import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Sf = () => {

	const data = useStaticQuery(graphql`
		query {
			logo: file(relativePath: { eq: "portfolio/identity/sf-logo.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed_noBase64
					}
				}
			},
			img1: file(relativePath: { eq: "portfolio/sf/01.png" }) {
				childImageSharp {
					fluid(maxWidth: 700) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
			img2: file(relativePath: { eq: "portfolio/sf/02.png" }) {
				childImageSharp {
					fluid(maxWidth: 700, quality: 100) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
			img3: file(relativePath: { eq: "portfolio/sf/03.png" }) {
				childImageSharp {
					fluid(maxWidth: 700, quality: 100) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
		}
	`)

	return(
		<Layout>
			<SEO title="Case Studies: Scientific Financial" />
			<div className="case-study">
				<div className="intro">
					<div className="brand">
						<Img className='mx-auto' width="160" fixed={data.logo.childImageSharp.fixed} />
					</div>
					<h1 className='page-title display'>_Scientific Financial Systems inc.</h1>
					<div className="base-img base-img-sf">
						<Img fluid={data.img1.childImageSharp.fluid} />
					</div>
				</div>

				<div className="row text-center">
					<div className="col-md-7 mx-auto">
						<p className='text-primary-dark mb-4'>Scientific Financial Systems are a market leader in using machine learning and data analysis to predict stock market trends and drive sales. Axe Edge rebranded their corporate and flagship product including a full redesign of their sales and marketing site.</p>
						<div className="mb-4">
							<a className='btn mr-2' href="https://scifinsys.com">scifinsys.com</a>
                            <a className='btn' href="https://www.quotient.buzz">quotient.buzz</a>
						</div>
						<ul>
							<li className='label label-primary mr-1'>Web Design</li>
							<li className='label label-primary mr-1'>Branding</li>
						</ul>
					</div>
				</div>

				<div className="content-img">
					<div className="row">
						<div className="col-md-6 mb-2 mb-md-0">
							<Img fluid={data.img2.childImageSharp.fluid} />
						</div>
						<div className="col-md-6">
							<Img fluid={data.img3.childImageSharp.fluid} />
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Sf
